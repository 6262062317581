import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import useCategories, { useCategoryByUuid } from '../../hooks/use-categories';
import {
	GROUP_BRAND,
	GROUP_CULINARY,
	useLocationsByCategory,
	useLocationsByGroupAndCategory,
} from '../../hooks/use-locations';

import * as styles from './categories.module.scss';

//
// LOGO
//
export const Category = ({ uuid, items }) => {
	const { name: category } = useCategoryByUuid(uuid);

	return (
		<div className={styles.category}>
			<h2 className={styles.header}>{category}</h2>

			{items.map(({ name, slug }, key) => (
				<div key={key} className={styles.item}>
					<Link to={slug}>{name}</Link>
				</div>
			))}
		</div>
	);
};

Category.defaultProps = {
	items: [],
	uuid: null,
};

Category.propTypes = {
	uuid: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			slug: PropTypes.string.isRequired,
			logo: PropTypes.shape({
				publicURL: PropTypes.string.isRequired,
			}).isRequired,
		})
	).isRequired,
};

//
// WRAPPER
//
const Wrapper = ({ group }) =>
	useCategories().map(({ uuid }, key) => {
		const locations =
			group === null
				? useLocationsByCategory(uuid)
				: useLocationsByGroupAndCategory(group, uuid);

		if (locations.length === 0) {
			return null;
		}

		return <Category key={key} uuid={uuid} items={locations} />;
	});

Wrapper.defaultProps = {
	group: null,
};

Wrapper.propTypes = {
	group: PropTypes.oneOf([GROUP_BRAND, GROUP_CULINARY]),
};

export default Wrapper;
