import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import useLocations, {
	GROUP_BRAND,
	GROUP_CULINARY,
	useLocationsByGroup,
} from '../../hooks/use-locations';
import { SVGIcon } from '../../components/icons';

import * as styles from './logos.module.scss';

//
// LOGO
//
export const Logo = ({
	location: {
		slug,
		logo: { publicURL: src },
	},
}) => (
	<Link to={slug} className={styles.logo}>
		<SVGIcon path={src} className={styles.svg} />
	</Link>
);

Logo.defaultProps = {
	location: null,
};

Logo.propTypes = {
	location: PropTypes.shape({
		slug: PropTypes.string.isRequired,
		logo: PropTypes.shape({
			publicURL: PropTypes.string.isRequired,
		}).isRequired,
	}),
};

//
// WRAPPER
//
const Wrapper = ({ group }) => {
	const locations = group !== null ? useLocationsByGroup(group) : useLocations();

	return locations.map((location, key) => <Logo key={key} location={location} />);
};

Wrapper.defaultProps = {
	group: null,
};

Wrapper.propTypes = {
	group: PropTypes.oneOf([GROUP_BRAND, GROUP_CULINARY]),
};

export default Wrapper;
