import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from './teaser.module.scss';

const Teaser = ({ image, title }) => {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>{title}</h2>
			<GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} />
		</div>
	);
};

Teaser.defaultProps = {
	title: null,
	image: null,
};

Teaser.propTypes = {
	title: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired,
};

export default Teaser;
